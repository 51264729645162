/* h3 {
    background: blue;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
  } */
  /* .variable-width .slick-slide p {
    background: blue;
    height: 100px;
    color: #fff;
    margin: 5px;
    line-height: 100px;
    text-align: center;
  } */

  /* Carousel Word */
  .center .slick-center .carousel-word {
    /* color: #e67e22; */
    opacity: 1;
    transform: scale(1);
    /* transition: all 0.1s ease-in-out; */
  }
  .center .carousel-word {
    opacity: 0.0;
    transform: scale(0.85);
    transition: all 200ms ease;
  }

  /* Carousel Image */
  .center .slick-center .carousel-image {
    opacity: 1;
    transform: scale(1) translateY(10px); /* Back to original position */
    transition: all 250ms ease;
  }
  .center .carousel-image {
    opacity: 0.8;
    transform: scale(0.95) translateY(70px); /* Starts 30px lower */
    transition: all 250ms ease;
  }

  /* Carousel Image Hover */
  .center .carousel-image:hover ~ .carousel-word {
    /* color: #e67e22; */
    opacity: 1;
    transform: scale(1);
    /* transition: all 0.1s ease-in-out; */
  }
  .center .carousel-image:hover {
    transform: scale(1);
  }

  /* Picture Item Hover Effect */
  /* .image-hover {
    transform: scale(0.95);
    transition: all 250ms ease;
  }
  .image-hover:hover {
    transform: scale(1);
  } */


  .content {
    padding: 20px;
    margin: auto;
    width: 90%;
  }
  .slick-slide .image {
    padding: 10px;
  }
  /* .slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin: auto;
  } */
  .slick-slide img.slick-loading {
    border: 0;
  }
  .slick-slider {
    margin: 30px auto 50px;
  }
  .slick-dots {
    margin-left: 0;
  }
  .slick-thumb {
    bottom: -45px;
  }
  .slick-thumb li {
    width: 60px;
    height: 45px;
  }
  .slick-thumb li img {
    filter: grayscale(100%);
  }
  .slick-thumb li.slick-active img {
    filter: grayscale(0%);
  }
  @media (max-width: 768px) {
    h3 {
      font-size: 24px;
    }
    .center {
      margin-left: -40px;
      margin-right: -40px;
    }
    .center .slick-center .carousel-image {
      opacity: 1;
      transform: scale(1);
    }
    .center .carousel-image {
      opacity: 0.8;
      transform: scale(0.95);
      transition: all 300ms ease;
    }
  }
  .slick-vertical .slick-slide {
    height: 180px;
  }
  /* .slick-arrow {
    background-color: grey;
    color: black;
  }
  .slick-arrow:hover {
    background-color: grey;
  } */
  /* .slick-prev {
    z-index: 100;
    left: 20px !important;
  }
  .slick-next {
    z-index: 100;
    right: 20px !important;
  } */
  

/* SCROLL BAR STYLING */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}